@import './people-products-variables';

.pp-breadcrumb {
  display: block;
  list-style-type: none;
  margin: 0 0 30px 0;
  padding: 0;

  > li {
    display: inline;
    margin: 0;
    padding: 0;
    color: $gray-8;
    font-size: $font-size-s;

    &.pp-breadcrumb-opener > span {
      cursor: pointer;
    }

    &.pp-breadcrumb-separator {
      margin: 0 10px;
      color: $gray-6;
      font-size: $font-size-xs;
    }

    &:not(:last-child) > a {
      color: $gray-8;
    }

    &:last-child:not(:first-child) > a {
      font-weight: bold;
    }

    &:last-child:not(:first-child) > span {
      color: $gray-9;
      font-weight: bold;
    }
  }
}

.pp-clickable {
  cursor: pointer;
}
