@import '../people-products-variables';

.status-text {
  font-size: $font-size-xs;
  font-weight: bold;
  padding: 0 6px;
}

.status-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  border-radius: 2px;
  &:not(.calibration-status-badge) {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.status-regular {
  .status-text {
    font-size: $font-size-xs;
    font-weight: normal;
    color: $secondary;
  }
}

.status-unsubmitted {
  color: $gray-9;
  background-color: $yellow-5;
}

.status-requested {
  color: #ffffff;
  background-color: $blue-9;
}

.status-draft {
  color: $gray-9;
  background-color: $blue-2;
}

.status-completed {
  color: #ffffff;
  background-color: $green-9;
}

.status-unavailable {
  color: #ffffff;
  background-color: $gray-7;
}

.status-available {
  color: #ffffff;
  background-color: $blue-9;
}

.status-rejected {
  color: #ffffff;
  background-color: $red-7;
}

.status-published {
  color: #ffffff;
  background-color: $blue-9;
}

.status-incomplete {
  color: $gray-9;
  background-color: $yellow-7;
}

.calibration-status-preparing {
  color: $gray-9;
  background-color: $gray-4;
}

.calibration-status-in_progress {
  color: #ffffff;
  background-color: $blue-9;
}

.calibration-status-completed {
  color: #ffffff;
  background-color: $green-9;
}

.calibration-status-draft {
  color: $gray-9;
  background-color: $gray-4;
}

.calibration-status-proposed {
  color: #ffffff;
  background-color: $blue-9;
}

.calibration-status-pending {
  color: $gray-9;
  background-color: $yellow-7;
}

.calibration-status-agreed {
  color: #ffffff;
  background-color: $green-9;
}
