$gray-0: #fafbfc;
$gray-1: #f2f4f6;
$gray-2: #ebedf0;
$gray-3: #e1e4e8;
$gray-4: #d2d8dd;
$gray-5: #b5bcc3;
$gray-6: #9299a0;
$gray-7: #5b6168;
$gray-8: #484e53;
$gray-9: #373b3e;

$blue-1: #dee6ff;
$blue-2: #c1cdff;
$blue-3: #9cafff;
$blue-4: #829afc;
$blue-5: #6c89fa;
$blue-6: #5e7cf6;
$blue-7: #5572ed;
$blue-8: #4e6bde;
$blue-9: #4a60cc;

$green-1: #ebfacd;
$green-2: #fff4c5;
$green-3: #c6ed83;
$green-4: #b1e55d;
$green-5: #9edb42;
$green-6: #8ece34;
$green-7: #82bf2d;
$green-8: #75b027;
$green-9: #6c9e25;

$yellow-1: #fff4c5;
$yellow-2: #ffeda3;
$yellow-3: #ffe375;
$yellow-4: #ffd84e;
$yellow-5: #fcca30;
$yellow-6: #fab81e;
$yellow-7: #f6a81a;
$yellow-8: #f1971a;
$yellow-9: #e8841a;

$red-1: #ffe5e5;
$red-2: #ffcece;
$red-3: #ffb0b0;
$red-4: #ff9393;
$red-5: #ff7a7a;
$red-6: #fa6363;
$red-7: #f15151;
$red-8: #e34646;
$red-9: #ce3f3f;

$primary-color: $blue-7;
$info-color: $blue-9;
$success-color: $green-7;
$warning-color: $yellow-7;
$danger-color: $red-7;
$error-color: $red-7;

$text-color: $gray-9;
$text-medium-color: $gray-7;

$text-muted-color: $gray-6;

$text-light-color: white;
$text-light-medium-color: $gray-1;

$text-error-color: $red-8;

$text-link-color: $blue-8;
$text-link-hover-color: $blue-7;

$dark-background-color: $gray-9;
$light-background-color: $gray-1;

$font-family: proxima-nova, Hiragino Sans, 'ヒラギノ角ゴ ProN W3', Hiragino Kaku Gothic ProN, sans-serif;

$font-size-xl: 22px;
$font-size-l: 18px;
$font-size-m: 16px;
$font-size-s: 14px;
$font-size-xs: 12px;

$icon-size-l: 18px;
$icon-size-s: 14px;
