@import './people-products-variables';

.pp-text-input-wrapper {
  > input {
    height: 44px;
    border: 0.5px solid $gray-9;
    border-radius: 3px;
    background-color: white;
    color: $gray-7;
    font-size: $font-size-s;

    &:focus {
      border: 1px solid $blue-7;
      box-shadow: inset 0 0 5px 0 $blue-7;
      background-color: white;
      color: $gray-9;
    }

    &:read-only {
      border-width: 0;
      background-color: $gray-1;
      pointer-events: none;
    }

    &:disabled {
      border-width: 0;
      background-color: $gray-1;
      color: $gray-6;
    }

    &.is-valid {
      background-color: white;
      &:focus {
        border: 1px solid $green-7;
        box-shadow: inset 0 0 5px 0 $green-7;
      }
    }
    &.is-invalid {
      background-color: white;
      &:focus {
        border: 1px solid #red-7;
        box-shadow: inset 0 0 5px 0 $red-7;
      }
    }
  }

  &.pp-text-input-wrapper-inline {
    display: inline-block;

    > input {
      display: inline;
      width: auto;
    }
  }

  &.pp-text-input-wrapper-with-icon {
    position: relative;

    > input {
      padding-right: 32px;
    }

    > .pp-text-input-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 20px;
      height: 20px;
      margin: auto 12px auto auto;

      svg {
        width: $icon-size-l;
        height: $icon-size-l;
      }

      &.pp-text-input-icon-valid {
        g {
          fill: $success-color;
        }
      }

      &.pp-text-input-icon-invalid {
        margin-right: 16px;
        color: $error-color;
      }

      &.pp-text-input-icon-clear {
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &.pp-text-input-wrapper-invalid ~ .invalid-feedback {
    display: block;
    color: $text-error-color;
    font-size: $font-size-xs;
  }
}

.pp-textarea {
  border: 0.5px solid $gray-9;
  border-radius: 3px;
  padding: 12px 20px;
  background-color: white;
  color: $gray-7;
  font-size: $font-size-s;

  &:focus {
    border: 1px solid $blue-7;
    box-shadow: inset 0 0 5px 0 $blue-7;
    background-color: white;
    color: $gray-9;
  }

  &:read-only {
    border-width: 0;
    cursor: default;
    background-color: $gray-1;
    box-shadow: none;
    color: $gray-7;
  }

  &:disabled {
    border-width: 0;
    background-color: $gray-1;
    color: $gray-6;
  }
}

.pp-dropdown {
  &.pp-dropdown-full {
    margin: 12px 0;

    &:first-child {
      margin-top: 0;
    }

    .dropdown-toggle {
      display: inline-block;
      width: 100%;
      text-align: left;
    }
  }

  .pp-button.pp-button-dropdown-toggle {
    color: $text-color;
    background-color: white;
    border: 1px solid $gray-6;

    &:hover:not(:disabled) {
      border-color: $primary-color;
    }

    &:disabled {
      opacity: 1;
    }
  }

  &.show > .pp-button.pp-button-dropdown-toggle {
    color: white;
    background-color: $gray-7;
  }

  .pp-dropdown-sub-text {
    color: $text-muted-color;
    font-size: $font-size-s;
    line-height: 100%;
    margin: 2px 0;
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button:active .pp-dropdown-sub-text,
  button.active .pp-dropdown-sub-text {
    color: white;
  }
}
