@import './people-products-variables';

.pp-list-container {
  > .row {
    &.list-header {
      border: 1px solid $gray-6;
      border-radius: 3px;
      margin-bottom: 4px;

      > .col {
        padding: 11px 9px;
        font-size: $font-size-m;
        font-weight: 600;
        color: $gray-7;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .list-row {
    background-color: #fafbfc;
    border-radius: 4px;
    border: 1px solid transparent;

    &:hover {
      background-color: #ebf0ff;
      border: 1px solid $blue-7;
    }

    > .col {
      padding: 11px 9px;

      /*
      > .employee-icon {
        width: 40px;
      }

      .member-name {
        margin-left: 10px;
        color: #4a4a4a;
        font-size: $font-size-m;
        font-weight: 600;
        letter-spacing: 0.1px;
        vertical-align: middle;
      }

      &.completed {
        .employee-icon {
          filter: grayscale(1);
        }

        .member-name {
          color: $gray-6;
        }
      }
      */

      .status-badge {
        vertical-align: middle;
        margin-top: 10px;
      }

      .view-span {
        font-size: $font-size-s;
        font-weight: 600;
        color: $blue-8;
        position: absolute;
        top: 20px; // align with status text
        right: 13px;

        div {
          display: inline-block;
        }

        .pp-icon {
          svg {
            margin-top: -3px;
            margin-left: 5px;

            g {
              fill: $primary-color;
            }
          }
        }
      }
    }
  }
}
