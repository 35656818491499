@import './_variables';
@import './people-products-variables';

.okr-container-request {
  width: 100% !important;
  padding: 10px 30px 30px;
  overflow-y: scroll;
  position: relative;
  top: 0;
  left: 0;
}

.okr-container-wrapper {
  width: inherit !important;
  margin-bottom: 50px !important;

  > .title-row {
    padding-right: 30px;
  }
}

.okr-approval-control {
  width: 100%;
  padding: 20px;
  position: relative;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 0;

  > .row {
    margin-bottom: 40px;
  }
}

.okr-container {
  padding: 10px 30px;
}

.employee-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;

  .employee-icon {
    flex: 0 0 40px;
  }

  .employee-name {
    flex: 1 1 auto;
    color: $text-color;
    font-weight: bold;
    font-size: $font-size-m;
    margin-left: 8px;
  }
}

.employee-name-header {
  color: #4a4a4a;
  font-weight: bold;
  margin-top: 15px;
}

.okr-screen-title {
  margin-bottom: 10px;
}

.okr-group-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
}

.okr-alert {
  padding: 13px !important;
  color: #4a4a4a;
}

.okr-managed-member-fixed-timestamp-badge {
  margin-top: -38px;
  margin-bottom: 31px;
}

.objective-card {
  border: solid 1px $primary-color;
  margin-bottom: 30px;
  > .card-body {
    color: $text-color;

    &.objective-body {
      padding-bottom: 0 !important;
    }

    &.kr-body {
      padding-top: 0 !important;
    }

    > .card-subtitle {
      margin-bottom: 10px !important;
      &.text-small {
        font-size: $font-size-s;
        line-height: 1.5em;
      }
    }

    > .card-title {
      margin-bottom: 20px !important;
      font-size: $font-size-l;
      font-weight: bold;
    }
  }
}

.kr-card {
  border: solid 1px #c9c9c9 !important;
  margin-bottom: 20px;
  > .card-body {
    color: rgba(74, 74, 74, 1);

    > .card-title {
      margin-bottom: 0 !important;
      font-weight: bold;
    }
  }

  > .kr-body {
    background-color: #f5f5f5;

    .card-title {
      font-size: $font-size-m;
      ~ div > button {
        margin-top: 16px;
      }
    }

    > .card-subtitle {
      margin-bottom: 5px !important;
      color: rgba(155, 155, 155, 1) !important;
      &.text-small {
        font-size: $font-size-s;
      }
    }
  }

  > .actionplan-body {
    border-top: 1px solid #c9c9c9;
    color: #4a4a4a;
    > .card-subtitle {
      color: #9b9b9b;
      margin-bottom: 5px;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.okr-search-side-nav {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.no-padding-col > .row > .col {
  padding: 0;
}

.no-padding {
  padding: 0;
}

/* OKR approval screen with new list design */
// TODO: Make all list style consistent - add to design system
.okr-members {
  .guide {
    margin: 15px 0 30px;
  }

  &.list-container {
    margin-bottom: 40px;
    > .row {
      &.list-header {
        border: 1px solid $gray-6;
        border-radius: 3px;
        margin-bottom: 4px;

        > .col {
          padding: 11px 9px;
          font-size: $font-size-m;
          font-weight: 600;
          color: $gray-7;
        }
      }
    }

    a {
      text-decoration: none;
    }

    .list-row {
      background-color: #fafbfc;
      border-radius: 4px;
      border: 1px solid transparent;

      &:hover {
        &:not(.dropdown-opened) {
          background-color: #ebf0ff;
          border: 1px solid $blue-7;
        }
      }

      > .col {
        padding: 11px 9px;

        /* TODO: Add to design system definition */
        > .employee-icon {
          width: 40px;
        }

        .member-name {
          margin-left: 10px;
          color: #4a4a4a;
          font-size: $font-size-m;
          font-weight: 600;
          letter-spacing: 0.1px;
          vertical-align: middle;
        }

        &.completed {
          .employee-icon {
            filter: grayscale(1);
          }

          .member-name {
            color: $gray-6;
          }
        }

        .status-badge {
          vertical-align: middle;
          margin-top: 10px;
        }

        .last-submitted-year-date {
          display: inline-block;
          vertical-align: middle;
          margin-top: 10px;
          line-height: 18px;
          font-weight: 600;
          color: #0d0d0d;

          &.with-in-one-week {
            margin-top: 0;
          }
        }

        .last-submitted-time {
          display: inline-block;
          vertical-align: middle;
          margin-top: 10px;
          margin-left: 5px;
          line-height: 18px;
          color: #0d0d0d;

          &.with-in-one-week {
            margin-top: 0;
          }
        }

        .submitted-with-in-one-week {
          font-size: 12px;
          line-height: 12px;
        }

        .drop {
          display: inline-block;
          vertical-align: middle;
        }

        .view-span {
          font-size: $font-size-s;
          font-weight: 600;
          color: $blue-8;
          position: absolute;
          top: 20px; // align with status text
          right: 13px;

          div {
            display: inline-block;
          }

          .pp-icon {
            svg {
              margin-top: -3px;
              margin-left: 5px;

              g {
                fill: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

.okr-edit-page {
  .common-reviewManagerAssignment {
    margin-bottom: 15px;

    // TODO: apply our design system to the ReviewManagerAssignment component and remove style below
    padding: 16px 20px;
    //border: 0.5px solid $gray-9;
    border: none;
    background-color: $gray-1;
    border-radius: 4px;
  }
}

.okr-editor {
  border: 1px solid $primary-color;
  border-radius: 4px;
  padding: 16px 20px;

  .okr-editor-header {
    .badge {
      margin-left: 16px;
      vertical-align: text-bottom;
      font-size: $font-size-xs;
    }
  }

  .okr-editor-guide {
    margin-top: 8px;
  }

  .kr-input {
    border: 1px solid $primary-color;
    border-radius: 4px;
    margin-top: 12px;
    padding: 10px;

    label {
      margin-bottom: 4px;
      color: $text-muted-color;
      font-size: $font-size-s;
    }

    > div:not(:first-child) {
      margin-top: 8px;
    }
  }

  .okr-form-footer {
    margin-top: 12px;
    text-align: right;
  }

  .dropdown-menu {
    overflow-y: scroll;
    max-height: calc(100vh - 340px);
  }
}

.parent-key-result {
  margin-top: 8px;
  .key-result-text {
    font-weight: bold;
  }
}

.okr-editor-col {
  // height = 100% - reviewsContainer.marginTop - reviewsContainer.marginBottom
  // - breadcrumb.boxHeight - reviewManagerAssignment.boxHeight - reviewManagerAssignment.marginBottom
  // - adjustment
  height: calc(100vh - 72px - 38px - 36px - 91px - 15px - 4px);
  overflow: scroll;

  .okr-editor {
    height: calc(100% - 68px); // 100 % - okrEditorActions.boxHeight
    overflow: scroll;
  }

  .okr-editor-actions {
    box-sizing: border-box;
    height: 68px;
    margin: 0;
    padding: 12px 0;
    text-align: right;
  }

  &.admin-okr-editor-col {
    // no reviewManagerAssignment - adjustment
    height: calc(100vh - 72px - 38px - 36px - 54px);
  }
}

.okr-form-actions {
  padding: 15px 10px;
  border-top: 1px solid $light;
  text-align: right;
  margin-bottom: 35px;
  background: #fff;

  button {
    min-width: 8em;
    margin-left: 15px;
  }
}

.okr-unsubmitted-button {
  font-size: 12px;
  padding: 5px;
}

.organization-team {
  border: 1px solid $gray-6;
  border-radius: 4px;
  margin: 0 20px 20px 20px;

  &.organization-team-0 {
    border-color: $primary-color;
    margin-left: 0;
    margin-right: 0;
  }

  .team-header {
    margin: 15px 20px;
  }

  .okr-owner {
    margin: 0;
    padding: 0 20px;

    .employee-header {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .team-okr {
    position: relative;

    &.opened {
    }

    &.closed {
      max-height: 150px;
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 1));
      }
    }
  }

  .objective-card {
    border: none;
    margin-bottom: 0;

    border-top: 0.5px solid $gray-6;
    border-radius: 0;

    .card-subtitle {
      margin-top: 0;
    }
  }
}

.objective-group-check {
  margin-top: 6px;

  .form-check-input {
    position: static;
    margin: 0 4px 0 0;
  }
}

.OKR-timestamp-badge {
  margin-top: -9px;
  float: right;
}

.OKR-saving-badge {
  float: right;
  margin-top: -9px;
}

.OKR-saving-badge-fixed {
  float: right;
  margin-top: -9px;
  display: flex;
  position: sticky;
  flex-wrap: wrap;
  z-index: 10000;
  top: 80px;
}

.okr-timestamp-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.okr-error-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
  top: 50px !important;
}

.okr-saving-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
  top: 50px !important;
}

.okr-saved-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
  top: 50px !important;
}

.okr-fixed-timestamp-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.okr-fixed-error-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.okr-fixed-saving-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.okr-fixed-saved-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}
