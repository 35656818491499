/**
 * calibration_screen
 */
@import './_variables';
@import './people-products-variables';

@keyframes hasUnsavedChange {
  0% {
    font-size: 0.9rem;
  }
  50% {
    font-size: 0.7rem;
  }
  100% {
    font-size: 0.9rem;
  }
}

/**
 * /calibration/screens
 */
.calibration-screens {
  &.container-fluid {
  }

  .row {
    &:first-child {
      border-top: solid 1px #f0f0f0;
    }
    border-bottom: solid 1px #f0f0f0;
    padding: 8px 0;

    &:hover {
      background-color: #f1f8fb;
    }

    &.list-header {
      background: #f0f0f0;
      font-weight: bold;
    }
    &.list-odd {
    }
    &.list-even {
    }
  }

  .clibration-screens-todos {
    margin-bottom: 18px;
  }
  .clibration-screens-done {
    margin-bottom: 18px;
  }

  &.calibration-screens-form {
    .form-check {
      margin-left: 10px;
    }
  }
}

/**
 * /calibration/screens/{id}
 */

.calibration-reviewers {
  img {
    margin-right: -10px;
  }
}

.calibration-review-info {
  border-bottom: 2px solid #9b9b9b;
  padding: 15px 0;
  margin-bottom: 5px;

  .btn {
    margin-top: 6vh;
  }

  .calibration-owner {
    font-weight: bold;

    .owner-header {
      margin-bottom: 10px;
    }
  }

  .calibration-reviewers {
    font-weight: bold;

    .reviewers-header {
      margin-bottom: 10px;
    }

    .border-blue {
      border: 2px solid #379eff;
    }
    .border-red {
      border: 2px solid #ff5796;
    }
    .border-gray {
      border: 2px solid #9b9b9b;
    }
  }

  .calibration-review-status-count-col {
    .calibration-progress-bar {
      width: 95%;
      bottom: 20px;

      div.progress {
        width: 450px;
        border-radius: 8px !important;
        margin-top: -25px;
        margin-bottom: 10px;

        .progress-bar {
          height: inherit;
          background-color: #17a2b8;
        }
      }
    }

    div {
      bottom: 0;
    }

    .calibration-review-status-count {
      margin-right: 10px;
    }
  }
}

.calibration-review-status-badge {
  letter-spacing: 1px;
  padding: 4px 7px;
}

.calibration-review-alert-status {
  color: #4a4a4a;
  padding: 10px;
  padding-bottom: 7px;
  position: relative;
  line-height: 22px;
  margin-bottom: 5px;

  &.info-status {
    border: 1px solid #a6ca00;
  }
  svg {
    color: #a6ca00;
    margin: auto 0;
    margin-right: 5px;
    font-size: 20px;
    display: inline-block;
  }

  > span {
    display: inline-block;
    position: absolute;
    top: 10px;
  }
}

.row.calibration-review-opened-summary {
  width: 100%;
  margin-left: 15px;
  margin-right: 8px;
  padding: 0 12px 12px;

  .calibration-review-section-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .calibration-review-self-assessment {
    .calibration-review-career-note {
      textarea {
        height: 240px;
      }
    }

    .calibration-review-text {
      .col {
        padding-top: 0;
      }
      textarea {
        height: 150px;
      }
    }
  }

  .calibration-review-peer-review {
    padding: 0 12px 0 25px;

    .reviewer-member {
      font-weight: bold;
      vertical-align: middle;

      span {
        margin-left: 6px;
      }
    }

    .col {
      padding: 0 !important;
      margin-bottom: 5px;
    }

    .peer-review-content {
      background-color: #e8e8e8;
      border-radius: 4px;
      padding: 15px;
      margin-bottom: 10px;

      .review-row-detail {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      .answer-index {
        color: $gray-6;
      }

      span {
        color: $gray-9;
      }
    }
  }
}

//table
.calibration-reviews {
  .row.calibration-reviews-header {
    position: sticky;
    top: 0;
    z-index: 1030;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    .review-sort-button {
      cursor: pointer;
    }

    .sort-link {
      a {
        color: #4a4a4a !important;
      }
      a:active {
        color: #4a4a4a !important;
      }
      a:visited {
        color: #4a4a4a !important;
      }
      &.active {
        text-decoration: underline solid black;
      }
    }

    .col {
      padding: 8px 4px;
      color: #4a4a4a;
    }

    .text-small {
      font-size: $font-size-xs;
    }
  }

  .calibration-reviews-virtualized-wrapper {
    margin: 0 -15px;

    .ReactVirtualized__List:focus {
      outline: none;
    }

    .ReactVirtualized__Grid__innerScrollContainer > .row {
      margin: 0;
      border-top: solid 1px #dee2e6;
      &:last-child {
        border-bottom: solid 1px #dee2e6;
      }

      &:hover > .col {
        background-color: #f1f8fb;
      }
    }
  }

  // base csss
  .row.calibration-review .col {
    padding: 8px 4px;

    &.calibration-review-target-name {
      //width: 150px;
      white-space: nowrap;

      &.has-unsaved-change {
        .requesting-status-mark,
        .changing-status-mark {
          color: #ccc;
          font-size: 0.9rem;

          span {
            animation-name: hasUnsavedChange;
            animation-duration: 3s;
            animation-iteration-count: infinite;
          }
        }
      }

      color: #4a4a4a;

      .target-join-date {
        font-size: 0.8em;
        margin-top: 4px;
      }
    }

    &.calibration-review-target-grade {
      z-index: 1000;
      white-space: nowrap;
      > div {
        text-align: center;
        display: inline-block;
        vertical-align: top;
      }

      select {
        display: inline;
        min-width: 50px;
        padding: 5px;
      }

      .calibration-review-target-salary {
        margin-left: 8px;

        input {
          display: inline;
          width: 80px;
          &:disabled {
            background-color: #fff;
          }
        }
        &.type-hourly input {
          width: calc(80px - 2ch);
        }

        button {
          padding: 0 5px;
        }

        .calibration-review-target-salary-gap {
          font-size: 0.8rem;
        }

        .calibration-review-target-salary-annuala-conv {
          font-size: 0.5rem;
        }
      }

      .calibration-review-target-point {
        white-space: nowrap;
        //width: 20px;
        margin-bottom: 4px;

        input {
          display: inline;
          width: 45px;
        }

        button {
          padding: 0 5px;
        }

        .calibration-review-target-point-gap {
          font-size: 0.8rem;
        }
      }

      .calibration-review-performance-rating {
        width: 100%;
        display: block;
        select {
          width: inherit !important;
        }
      }

      .calibration-review-target-incentive {
        > div {
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }

        .granted-date {
          font-size: $font-size-xs;
        }

        label {
          margin-bottom: 0;
        }

        .cash-incentive {
          span.incentive-amount {
            &::before {
              content: '\1F4B0';
            }
          }
        }

        .stock-incentive {
          span.incentive-amount {
            &::before {
              content: '\1F4C8';
            }
          }
        }
      }
    }

    &.calibration-review-review-summary {
    }
    &.calibration-review-feedback {
    }
  }

  // opened
  .row.calibration-review.review-opened {
    background: rgba(222, 230, 255, 0.35);

    &:hover .col {
      background: transparent;
    }
  }
  .row.calibration-review.review-opened .col {
    &.calibration-review-review-summary {
      textarea {
        height: 250px;
      }
    }
    &.calibration-review-review-feedback {
      textarea {
        height: 250px;
      }
    }

    .reviews-screen-review-container {
      @at-root {
        @media screen and (max-width: 1600px) {
          & {
            margin-top: 0;
          }
        }
      }

      margin-top: -105px;
      margin-left: -13px;

      padding-right: 0;
      div.col {
        padding-right: 0;
      }
    }
  }

  .calibration-review-click-to-open {
    display: none;
  }

  .review-summary-action-open {
    background-color: #ffffff;
    border: 1px solid #777ffb;
    color: #777ffb;
  }

  .row.calibration-review.review-closed {
    .col {
      &.calibration-review-feedback,
      &.calibration-review-review-summary {
        position: relative;
        max-height: 70px;
        overflow: hidden;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &:hover {
      .calibration-review-click-to-open {
        display: block;
        position: absolute;
        top: 8px;
        right: 0;
        background: rgba(200, 200, 200, 0.8);
        /* border: solid 1px #ccc; */
        border-radius: 8px;
        padding: 8px;
        width: 200px;
        text-align: center;
        z-index: 1030;
      }
      .calibration-review-feedback,
      .calibration-review-review-summary {
        &::after {
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(241, 248, 251, 1) 70%);
        }
      }
    }
  }
}

// filters
.calibration-filters {
  margin-bottom: 50px;

  h2 {
    font-size: 1rem;
    padding: 8px;
  }

  .calibration-filter {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #f0f0f0;
  }

  strong {
    margin-top: 8px;
  }

  label {
    display: block;
    margin: 8px 0 0 0;

    .form-check-input {
      position: static;
      margin: 0;
    }
  }
}

// navigation
.nav-tabs.calibration-salary-update-history {
  width: 100%;
  margin: 10px 15px 0 15px;
  border-bottom: 2px solid #909090;

  .nav-item {
    align-self: flex-end;
    padding: 4px 24px;
    margin-bottom: -2px;

    &.active {
      border-bottom: 4px solid #6b74fa;
      font-weight: bold;
    }

    .salary-update-cycle {
      text-align: center;
      font-size: 0.9rem;
      line-height: 1;
    }

    ul.salary-update-summary {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;

      > li {
        display: block;
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        color: #e0a800;

        &:not(:first-child)::before {
          content: '/';
          padding: 0 4px;
        }
      }
    }
  }
}

// comments and history
.calibration-review-comments-and-history {
  padding-top: 8px;
  padding-right: 8px;

  > form > .row {
    margin: 0;
  }

  .calibration-history-comment {
    .row {
      margin-left: 0;
      margin-right: 0;
      justify-content: space-between;

      .comment-commenter {
        padding-bottom: 4px;
        font-weight: bold;
        font-size: 0.9rem;
      }
      .comment-date {
        padding-bottom: 4px;
        font-size: 0.8rem;
        color: $secondary;
      }
      .comment-text {
        padding-top: 0;
        padding-left: 32px;
        padding-bottom: 0;
        font-size: 0.9rem;
        word-wrap: break-word;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px $light solid;
      margin-bottom: 4px;
      padding-bottom: 6px;
    }
  }

  .calibration-history-calibration-title {
    margin-top: 8px;
    font-size: 1rem;
    font-weight: bold;
  }

  .calibration-history-review-result {
    background-color: #f1f1f1;
    border-radius: 6px;
    margin: 4px 0;
    padding: 0 8px;

    .col {
      font-size: 0.8rem;
      .review-result-value {
        color: #e0a800;
      }
    }
  }
}

// performance review and peer review
.calibration-review-performance-and-peer {
  padding-left: 8px;
  > .row {
    margin: 0;
  }

  textarea {
    min-height: 400px;
  }

  .calibration-review-career-note {
    textarea {
      min-height: 240px;
    }
  }
}

// stats
.container-fluid.calibration-stats {
  margin-top: 24px;
  padding: 0;

  .col {
    padding-bottom: 8px;
  }

  label {
    display: inline;
    margin-right: 12px;
    font-weight: bold;
  }

  input {
    text-align: right;
    &.target-member-count {
      width: 80px;
    }
    &.salary-raise-total {
      width: 120px;
    }
    &.salary-raise-average {
      width: 120px;
    }
    &.salary-raise-rate {
      width: 80px;
    }
  }
}

.calibration-review-edit-anyway-alert {
  padding: 8px 12px !important;

  > .alert {
    margin-bottom: 0;
  }
}

.calibration-review-check-wrapper {
  float: left;
  width: 20px;
  height: 100%;

  .form-check-input {
    margin: 0;
    position: static;
  }
}

.calibration-review-toggle {
  text-align: right;

  .btn {
    margin: 0 8px 0 0;
  }
}

.calibration-extra-reviewers {
  height: 44px;
  width: 44px;
  border: 11px solid #ff9393;
  background-color: #ff9393;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
}

.tooltip-calibration-reviewers {
  margin-left: 5px;
  .tooltip-inner {
    width: 291px;
    max-width: max-content;
    padding: 6px 8px;
    color: #fff;
    text-align: justify;
    background-color: #000;
    border-radius: 0.25rem;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.99);
    top: -15px;
  }

  .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
    top: -6px;
  }
}

@mixin style-has-change() {
  box-shadow: 0 0 30px gold;
}

.has-grade-changed {
  .has-change {
    @include style-has-change();
  }
}

.disabled-prev-quarter {
  color: #9299a0;
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  cursor: default;
  bottom: -9px;
}

.disabled-new-quarter {
  color: #9299a0;
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  cursor: default;
  bottom: -9px;
  margin-right: 30px;
}
