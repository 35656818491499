@import './people-products-variables';

.pp-alert {
  padding: 12px 16px;
  border: 1px solid;
  border-radius: 4px;
  font-size: $font-size-s;

  &.pp-alert-icon {
    > .pp-icon {
      margin-right: 8px;
      display: inline-block;

      svg {
        width: $icon-size-l;
        height: $icon-size-l;
      }
    }
  }

  &.pp-alert-info {
    color: $info-color;
    background-color: rgba(85, 114, 237, 0.1);
    border-color: $info-color;
  }

  &.pp-alert-normal {
    color: $text-color;
    background-color: rgba(246, 168, 26, 0.1);
    border-color: $warning-color;
  }

  &.pp-alert-success {
    color: $green-9;
    background-color: rgba(130, 191, 45, 0.1);
    border-color: $success-color;
    .pp-icon {
      g {
        fill: $success-color;
      }
    }
  }

  &.pp-alert-error {
    color: $text-error-color;
    background-color: rgba(241, 81, 81, 0.1);
    border-color: $error-color;
    .pp-icon {
      g {
        fill: $error-color;
      }
    }
  }

  > button.close {
    color: $gray-6;
    opacity: 1;
    &:hover {
      color: $gray-8;
    }
  }

  > ul {
    margin: 0;
    padding-left: 16px;
  }
}
