@import './people-products-variables';

.admin-container {
  .admin-content-filter {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .nav-tabs {
    .nav-link.active {
      background-color: $gray-1;
      border-bottom-color: $gray-3;
    }
  }
}

.admin-home {
  display: grid;
  grid-template-columns: 800px 320px;
  grid-template-rows: 1fr auto;
  grid-gap: 40px;

  &-main {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  &-teams-sync {
    grid-column: 2;
    grid-row: 1;
    padding: 16px;
    border-radius: 4px;
    background-color: rgba(85, 114, 237, 0.05);

    &-title {
      margin-bottom: 16px;
      font-size: $font-size-xl;
    }

    &-subtitle {
      font-size: $font-size-m;
    }

    &-action {
      margin-bottom: 16px;
      .btn,
      .pp-button {
        min-width: 100%;
        width: 100%;
      }
    }

    &-info {
      margin-bottom: 24px;
    }

    &-logs {
      list-style: none;
      padding-left: 0;
      font-size: $font-size-s;
    }
    &-logs-item {
      margin-bottom: 4px;
    }
  }

  &-review-elements {
    grid-column: 2;
    grid-row: 2;
    padding: 16px;
    border-radius: 4px;
    background-color: rgba(85, 114, 237, 0.05);

    &-title {
      margin-bottom: 16px;
      font-size: $font-size-xl;
    }

    &-action {
      margin-bottom: 16px;
      .btn,
      .pp-button {
        min-width: 100%;
        width: 100%;
      }
    }

    &-info {
      margin-bottom: 24px;
    }
  }
}

.teams-sync-status-info {
  &-title {
    margin-bottom: 4px;
    font-weight: 400;
    &::after {
      margin-left: 0.16em;
      content: ':';
    }
  }
  &-data {
    margin-bottom: 12px;
  }
}

.review-elements-status-info {
  &-title {
    margin-bottom: 4px;
    font-weight: 400;
    &::after {
      margin-left: 0.16em;
      content: ':';
    }
  }
  &-data {
    margin-bottom: 12px;
  }
}

.settings-table {
  thead th {
    min-width: 70px;

    &:not(:first-child) {
      border-left: 1px solid $gray-1;
    }

    > span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }

  tbody td {
    text-align: center;
    vertical-align: middle;

    &:not(:first-child) {
      border-left: 1px solid $gray-1;
    }

    &.changed {
      background-color: $blue-1;
    }

    &.boolean-cell {
      cursor: pointer;
      font-size: $font-size-xl;

      &.on {
        color: $green-8;
      }

      &.off {
        color: $text-muted-color;
      }
    }

    .pp-text-input-wrapper > input {
      min-width: 200px;
    }

    select.form-control {
      min-width: 150px;
    }
  }

  tfoot td {
    .badge {
      font-size: $font-size-s;
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .settings-table {
    thead th > span {
      word-break: break-all;
    }
  }
}
