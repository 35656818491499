@import './people-products-variables';

body {
  color: $text-color;
  font-family: $font-family;
  //font-size: $font-size-m;
}

a {
  text-decoration: none;

  &:link,
  &:active,
  &:visited {
    color: $text-link-color;
  }

  &:hover {
    color: $text-link-hover-color;
  }
}

.text-weak {
  color: $text-medium-color;
}

.text-muted {
  color: $text-muted-color;
}

.text-regular {
  color: $text-light-color;
}

.text-light-weak {
  color: $text-light-medium-color;
}

.text-error {
  color: $text-error-color;
}

.heading-1 {
  font-size: $font-size-xl;
  font-weight: bold;
}

.heading-1-regular {
  font-size: $font-size-xl;
  font-weight: normal;
}

.heading-2 {
  font-size: $font-size-l;
  font-weight: bold;
}

.heading-2-regular {
  font-size: $font-size-l;
  font-weight: normal;
}

.heading-3 {
  font-size: $font-size-m;
  font-weight: bold;
}

.heading-3-regular {
  font-size: $font-size-m;
  font-weight: normal;
}

.heading-4 {
  font-size: $font-size-s;
  font-weight: bold;
}

.heading-4-regular {
  font-size: $font-size-s;
  font-weight: normal;
}

.heading-5 {
  font-size: $font-size-xs;
  font-weight: bold;
}

.heading-5-regular {
  font-size: $font-size-xs;
  font-weight: normal;
}
