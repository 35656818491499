@import './people-products-variables';

button.pp-button {
  box-sizing: border-box;
  min-width: 96px;
  height: 44px;
  padding: 4px 16px;
  border: 1px solid;
  border-radius: 4px;
  font-size: $font-size-m;
  font-weight: normal;
  text-transform: none;
  line-height: 100%;
  vertical-align: baseline;

  ~ button.pp-button {
    margin-left: 16px;
  }

  > .pp-icon {
    display: inline-block;

    svg {
      width: $icon-size-l;
      height: $icon-size-l;
    }
  }

  &.pp-button-icon-left {
    padding-left: 12px;
    > .pp-icon-left {
      margin-right: 8px;
    }
  }

  &.pp-button-icon-right {
    padding-right: 12px;
    > .pp-icon-right {
      margin-left: 8px;
    }
  }

  &.pp-button-small {
    min-width: 72px;
    height: 32px;
    padding: 4px 16px;
    font-size: $font-size-s;

    > .pp-icon {
      svg {
        width: $icon-size-s;
        height: $icon-size-s;
      }
    }

    &.pp-button-icon-left {
      padding-left: 12px;
      > .pp-icon-left {
        margin-right: 5px;
      }
    }

    &.pp-button-icon-right {
      padding-right: 12px;
      > .pp-icon-right {
        margin-left: 5px;
      }
    }

    &.pp-button-font-size-m {
      font-size: $font-size-m;
    }
  }

  &.pp-button-icon-only {
    min-width: auto;
    width: 44px;
    padding: 4px;

    &:not(:first-child) {
      margin-left: 8px;
    }

    &.pp-button-small {
      width: 32px;
    }
  }

  &.pp-button-toggle {
    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }

    &.pp-button-no-outline {
      border: none;
    }

    .pp-icon {
      svg {
        width: 12px;
        height: 12px;
      }

      /* For aligning arrows with text*/
      &.arrow_up {
        svg {
          margin-top: -3px;
        }
      }

      &.arrow_down {
        svg {
          margin-top: -1px;
        }
      }
    }
  }

  &.pp-button-primary {
    color: $text-light-color;
    background-color: $primary-color;
    border-width: 0;

    .pp-icon {
      g {
        fill: $text-light-color;
      }
    }

    &:active {
      background-color: $blue-5;
      &:focus {
        background-color: $blue-5;
      }
    }

    &:hover {
      background-color: $blue-5;
    }

    &:disabled {
      color: #9299ad;
      background-color: $gray-4;

      .pp-icon {
        g {
          fill: #9299ad;
        }
      }
    }
  }

  &.pp-button-secondary {
    color: $primary-color;
    background-color: white;
    border-color: $blue-7;

    .pp-icon {
      svg {
        height: 12px;
        width: 12px;
        g {
          fill: $primary-color;
        }
      }
    }

    &:active {
      background-color: $blue-1;
      &:focus {
        color: $primary-color;
        background-color: $blue-1;
      }
    }

    &:hover {
      background-color: $blue-1;
    }

    &:disabled {
      color: $gray-6;
      border-color: $gray-6;
      background-color: white;

      .pp-icon {
        g {
          fill: $gray-6;
        }
      }
    }
  }

  &.pp-button-warning {
    color: $text-color;
    background-color: $warning-color;
    border-width: 0;

    &:active {
      background-color: $yellow-6;
      &:focus {
        background-color: $yellow-6;
      }
    }

    &:hover {
      background-color: $yellow-6;
    }

    &:disabled {
      color: $gray-6;
      background-color: $gray-4;

      .pp-icon {
        g {
          fill: $gray-6;
        }
      }
    }
  }

  &.pp-button-danger {
    color: $text-light-color;
    background-color: $danger-color;
    border-width: 0;

    &:active {
      background-color: $red-6;
      &:focus {
        background-color: $red-6;
      }
    }

    &:hover {
      background-color: $red-6;
    }

    &:disabled {
      color: $gray-6;
      background-color: $gray-4;

      .pp-icon {
        g {
          fill: $gray-6;
        }
      }
    }
  }

  &.pp-button-danger.pp-button-icon-only {
    color: $danger-color;
    background-color: white;
    border: 1px solid $danger-color;

    .pp-icon {
      g {
        fill: $danger-color;
      }
    }

    &:active {
      color: white;
      background-color: $danger-color;
      border-color: $danger-color;
      &:focus {
        background-color: $danger-color;
        border-color: $danger-color;
      }
    }

    &:hover {
      background-color: $danger-color;

      .pp-icon {
        g {
          fill: white;
        }
      }
    }

    &:disabled {
      color: $gray-6;
      background-color: $gray-4;
      border-width: 0;
      pointer-events: none;

      .pp-icon {
        g {
          fill: $gray-6;
        }
      }
    }
  }
}
