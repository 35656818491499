.maintenance-root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  text-align: center;
  flex-direction: column;

  .image-root {
    position: relative;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    position: relative;
  }

  .subtext {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #000000;
    position: relative;
    margin-top: 10px;
  }
}
