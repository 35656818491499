/**
 * ItemSelector
 */
.item-selector {
  .item-selector-input {
  }

  .item-selector-target-list {
    border: 1px solid #ced4da;
    background-color: #eee;
    position: absolute;
    z-index: 10000;
    min-height: 2rem;
    max-height: 200px;
    overflow-y: auto;

    .item-selector-list-container {
      display: flex;
      flex-direction: column;

      .item-selector-target-item {
        width: 100%;

        .item-selector-selected-icon {
          width: 12px;
          margin-left: 4px;
          margin-right: 4px;
        }

        .item-selector-not-selected-icon {
          opacity: 0.2;
          width: 12px;
          margin-left: 4px;
          margin-right: 4px;
        }

        .item-selector-target-item-link {
          display: block;
          padding: 6px 4px;
          width: auto;
          color: #212529;
          text-decoration: none;
        }

        .item-selector-target-item-link:hover {
          background-color: #f1f8fb;
        }
      }
    }
  }

  .item-selector-selected-list {
    border: 1px solid #ced4da;
    border-radius: 4px;
    min-height: 2rem;
    margin-top: 4px;

    .item-selector-selected-item {
      margin: 4px;
      padding: 4px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #eee;
      color: #666;

      .item-selector-selected-item-icon {
        cursor: pointer;
        margin-left: 4px;
        margin-right: 4px;
      }
      .item-selector-selected-item-name {
        margin-left: 2px;
      }
    }
  }
}
