.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-container {
  border: 1px solid rgb(152, 153, 154);
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-radius: 1px;
  &.read-only {
    border: none;
    .editor-inner {
      border-radius: 4px;
      background: #f2f4f6;
      border: 1px solid #98999a;
      color: #373b3e;
    }
  }
}

.editor-inner {
  background: #fff;
  position: relative;
  overflow: auto;
}

.editor-input {
  min-height: 150px;
  resize: none;
  font-size: 15px;
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;

  ul {
    list-style-type: disc;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
  }
  ul ul ul,
  ol ol ul,
  ol ul ul,
  ul ol ul {
    list-style-type: square;
  }
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
  white-space: pre;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-quote {
  margin: 0;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.editor-list-ol {
  padding: 0;
  margin: 0 0 0 16px;
}

.editor-list-ul {
  padding: 0;
  margin: 0 0 0 16px;
}

.editor-listitem {
  margin: 8px 0 8px 16px;
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.editor-container.active .toolbar {
  display: flex;
}

.toolbar {
  display: none;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;

  &.hide {
    display: none;
  }
}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item:not(:last-child) {
  margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  background-color: #dfe6fd;
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.link-editor {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  will-change: transform;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.link-editor button {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  cursor: pointer;
  vertical-align: middle;
}

.link-editor .link-input {
  display: block;
  width: 100%;
  padding: 6px 12px;
  border: 0.5px solid #373b3e;
  height: 30px;
  outline: none;
}

.link-editor {
  & .link-view,
  & .link-edit {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 13px 12px;
    gap: 6px;
    height: 56px;
  }

  & .link-view .link-text {
    color: #4e6bde;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.floating-text-format-popup {
  display: flex;
  background: #fff;
  padding: 4px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
  will-change: transform;
}

.floating-text-format-popup button.popup-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.floating-text-format-popup button.popup-item.spaced {
  margin-right: 2px;
}

.floating-text-format-popup button.popup-item.active {
  background-color: #dfe6fd;
}

.floating-text-format-popup .popup-item:hover:not([disabled]) {
  background-color: #eee;
}
