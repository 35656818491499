.common-reviewManagerAssignment {
  border: solid 2px #999;
  padding: 10px;
  .manager-name {
    font-weight: bold;
    font-size: 1.2em;
    margin-left: 8px;
  }
  .manager-icon img {
    width: 36px;
    border-radius: 18px;
  }

  .additional-texts {
  }
}
