@import './people-products-variables';

.pp-dropdown {
  &.pp-dropdown-more {
    .opened {
      background-color: $blue-1;
    }

    > .dropdown-more-content {
      position: absolute;
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid $blue-7;
      min-width: 160px;
      padding: 5px 0;
      margin-top: 2px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
      z-index: 1;

      > span {
        display: block;
        padding: 7px 15px;
        &:hover {
          background-color: $blue-1;
        }
      }
    }
  }

  .pp-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
}
