/**
 * peer review
 */
@import './_variables';

// general
.peer-review.container-fluid:not(.list-container) {
  margin: 15px 0;

  > .row {
    &.list-header {
      border-bottom: 1px solid $secondary;

      > .col {
        padding: 6px 4px;
        font-weight: bold;
        font-size: smaller;
        color: $secondary;
      }
    }

    > .col {
      padding: 15px 4px;

      .member-name {
        margin-left: 8px;
        color: $dark;
      }

      .status-label {
        font-size: smaller;
      }

      &.sub-list-container {
        padding: 0 0 0 24px;

        .sub-list {
          background: #fafafa;
          border: 1px solid $light;
          border-radius: 4px;

          > .row {
            &.list-header {
              border-bottom: 1px solid $light;

              > .col {
                padding: 6px 15px;
                font-weight: bold;
                font-size: smaller;
                color: $secondary;
              }
            }

            > .col {
              padding: 5px 15px;
              &:first-child {
                padding-top: 10px;
              }
            }
          }

          // deeply nested list
          .sub-list {
            background: #fff;
            .col.question-index {
              padding-bottom: 0;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}

// review
.peer-review-content {
  .form-group {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .row {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    
    .col {
      &.question-index {
        padding-top: 0;
        padding-bottom: 0;

        .label {
          margin-left: 10px;
          vertical-align: baseline;
          font-size: 12px;
          border-radius: 10px;
          padding: 2px 10px;
          &.required {
            background-color: $red-7;
            color: #fff;
          }
          &.optional {
            background-color: #9299a0;
            color: #fff;
          }
        }
      }

      label {
        margin: 0;
        color: $dark;
      }
      .help-text {
        margin-top: 10px;
        color: $red-7;
      }
      .label-text {
        padding: 4px;
      }
      .answer-text {
        border-radius: 4px;
        background-color: #f5f5f5;
        min-height: 100px;
        padding: 4px;
      }
    }
  }

  .peer-review-actions {
    padding: 10px 0 15px 0;
  }
}

// actions
.peer-review-actions {
  text-align: right;
}

// reviewer request
.peer-reviewer-approval {
  padding: 15px 0;

  .peer-review-actions {
    margin-top: 15px;
  }
}

// member search
.search-container.member-search {
  margin: 15px 0;

  input {
    width: 200px;
  }

  .suggestion-container {
    position: relative;
    ul.search-suggestions {
      position: absolute;
      z-index: 100;

      list-style-type: none;
      background: #ffffff;
      min-width: 190px;
      margin: 0 5px;
      padding: 0;
      border: 1px solid #dee2e6;
      border-top-width: 0;
      border-radius: 0 0 6px 6px;

      > li {
        cursor: pointer;
        margin: 0;
        padding: 6px 8px;
        border-top: 1px solid #dee2e6;
        background-color: #fff;

        &.active,
        &:hover {
          background-color: #fffae6;
        }

        &:last-child {
          border-radius: 0 0 6px 6px;
        }

        &.search-suggestion-empty {
          cursor: default;
          background-color: #fefefe;
          &:hover {
            background-color: #fefefe;
          }
        }

        &.search-suggestion-error {
          cursor: default;
          background-color: #fefefe;
          &:hover {
            background-color: #fefefe;
          }
        }
      }
    }
  }
}

.search-container.member-search.header-search-item {
  margin: 0 20px;

  input {
    width: 300px;
  }
}

// guide
.peer-review-guide {
  margin-top: 10px;
  h5 {
    font-size: 1rem;
  }
}

/* Peer review screens with new list design */
// TODO: Make all list style consistent - add to design system
.peer-review {
  &.list-container {
    > .row {
      &.list-header {
        border: 1px solid $gray-6;
        border-radius: 3px;
        margin-bottom: 4px;

        > .col {
          padding: 11px 9px;
          font-size: $font-size-m;
          font-weight: 600;
          color: $gray-7;
        }
      }
    }

    > .member-reviewer-list-row {
      background-color: #fafbfc;
      border-radius: 4px;
      border: 1px solid transparent;

      &:hover {
        /* TODO: Add to design system definition */
        background-color: #ebf0ff;
        border: 1px solid $blue-7;
      }

      &.disable-hover {
        &:hover {
          background-color: #fafbfc;
          border: 1px solid transparent;
        }
        .pp-button-toggle.disable-hover:hover {
          background-color: $blue-1 !important;
        }
      }

      .pp-button-toggle {
        font-size: $font-size-s;
        font-weight: 600;
        float: right;
        margin-top: 0.25rem;
      }

      > .col {
        padding: 11px 9px;

        /* TODO: Add to design system definition */
        > .employee-icon {
          width: 40px;
        }

        .member-name {
          margin-left: 10px;
          color: #4a4a4a;
          font-size: $font-size-m;
          font-weight: 600;
          letter-spacing: 0.1px;
          vertical-align: middle;
        }

        &.approved {
          .employee-icon {
            filter: grayscale(1);
          }

          .member-name {
            color: $gray-6;
          }
        }

        .status-badge {
          vertical-align: middle;
          margin-top: 10px;
        }

        &.sub-list-container {
          padding-top: 0;

          .sub-list {
            padding: 0;

            .peer-reviewer-list-div {
              padding: 20px;
              background-color: #ffffff;
              border: 1px solid $gray-5;
              border-radius: 4px;

              > .list-header {
                margin-bottom: 15px;
                font-weight: 500;
              }

              /* TODO: Add to design system definition */
              .employee-icon {
                width: 20px;
              }

              .member-name {
                font-weight: normal;
              }

              .toggle-row {
                margin: auto -10px;
                padding: 0 10px;
                border-radius: 4px;

                &.opened {
                  padding-bottom: 10px;
                  background-color: $gray-1;
                }

                &:hover {
                  background-color: $blue-1;
                }

                > a {
                  .row {
                    padding: 10px 0;

                    &:not(.disabled):hover {
                      cursor: pointer;
                    }
                  }
                }

                > .sub-list-container {
                  background-color: #ffffff;
                  padding: 15px;
                  border-radius: 4px;

                  &:hover {
                    cursor: default;
                  }
                }
              }

              .no-outline {
                border: none;
                font-size: 14px;
                background-color: transparent;
                height: auto;
              }
            }

            .row {
              &:not(:last-child) {
                margin-bottom: 10px;
              }
            }

            .peer-reviewer-approval {
              .pp-button {
                float: right;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    // memo: this definition was copied from .member-reviewer-list-row
    > .received-review-row {
      background-color: #fafbfc;
      border-radius: 4px;
      border: 1px solid transparent;

      > .col {
        padding: 11px 9px;

        > .employee-icon {
          width: 40px;
        }

        .member-name {
          margin-left: 10px;
          color: #4a4a4a;
          font-size: $font-size-m;
          font-weight: 600;
          letter-spacing: 0.1px;
          vertical-align: middle;
        }

        &.sub-list-container {
          padding-top: 0;

          .peer-review-content {
            padding: 20px;
            background-color: #ffffff;
            border: 1px solid $gray-5;
            border-radius: 4px;
          }

          .sub-list {
            .row {
              &:not(:last-child) {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

// reviewer approval guide
// TODO: Make guide style consistent
.approval-guide {
  margin: 15px 0;
}

.reviewer-reject-modal {
  .modal-header {
    padding-bottom: 0;
    border-bottom: none;
  }

  .modal-footer {
    padding-top: 0;
    border-top: none;
  }

  .pp-textarea {
    resize: none;
  }
}

.peer-review-timestamp-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.peer-review-error-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.peer-review-saving-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.peer-review-saved-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}
