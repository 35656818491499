/**
 * feedback
 */
@import './people-products-variables';

.feedback-info {
  padding-left: 0;
  padding-right: 0;

  border-bottom: solid 1px #ccc;
  margin-bottom: 16px;

  > .row {
    margin-bottom: 8px;
  }
}

.show-on-hover {
  .value {
    font-size: 25px;
    font-weight: bold;
    opacity: 0;
    .currency {
      font-size: 20px;
      font-weight: normal;
      margin-left: 4px;
    }
    &.prev-value {
      color: #6c757d;
    }
    &.target-value {
      color: #273135;
      .currency {
        color: #273135 !important;
      }
    }
  }

  .note {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    .note {
      opacity: 0;
    }
    .value {
      opacity: 1;
    }
  }

  @media print {
    .arrow {
      display: none;
    }
    .note {
      opacity: 0;
    }
    .value {
      opacity: 1;
    }
  }
}

.wiki-link-row {
  margin-bottom: 1em;
}

.feedback-content {
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  .arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #6c757d;
  }
}

.incentive-content {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  .row:last-child {
    padding-bottom: 16px;
  }

  h4 {
    margin: 0;
  }

  .incentive-label {
    font-size: 18px;
    margin: 0;
    margin-left: 4px;
  }

  .value {
    font-size: 25px;
    font-weight: bold;
    opacity: 0;
    margin: 0;
    margin-left: 4px;

    .currency {
      font-size: 20px;
      font-weight: normal;
      margin-left: 4px;
    }
  }
}

.estimated-annual-salary-icon {
  font-size: 40px;
  color: #6c757d;
}

.estimated-annual-salary-about {
  color: #6c757d;
  font-size: small;
}

.feedback-input-total-point {
  input {
    width: 80px;
  }
}

/* TODO: Make common scss file */
.label {
  vertical-align: middle;
  margin-top: -3px;
  font-size: 12px;
  border-radius: 10px;
  padding: 2px 10px;
  &.required {
    background-color: $red-7;
    color: #fff;
  }
  &.optional {
    background-color: #9299a0;
    color: #fff;
  }
}

.feedback-publish-area {
  .alert {
    p {
      margin-bottom: 0;
    }
  }
  .button-row {
    margin-top: 1em;
    margin-bottom: 1em;

    button {
      margin-left: 0.5em;
    }
  }

  .feedback-point-row {
    margin-bottom: 1.5em;
  }

  .manager-row {
    margin: 1em 0;

    .col {
      margin: 0;
      padding: 0;
    }
  }

  .feedback-input-overall-assessment,
  .feedback-input-feedback {
    h3 {
      margin: 0;
    }
  }

  .notice {
    color: #ff0000;
    font-size: small;
    display: inline-block;
    margin-bottom: 5px;
    margin-top: -10px;
  }
}

.feedback-only-manager-area {
  margin-top: 1em;
  padding: 1em;
  background-color: #fffdf4;

  .feedback-input-careernote {
    .notice {
      color: #000000;
      font-size: small;
    }

    textarea {
      height: 8em;
    }
  }

  .calibration-history-row {
    margin-top: 1em;

    .calibration-history-header {
      font-weight: bold;
      padding: 0.2em 0.2em;
    }

    .calibration-history-row {
      margin-top: 0em;
      padding: 0.2em 0.2em;

      select {
        background-color: transparent;
        border: none;
        padding: 0;
        margin-left: -3px;
        -o-appearance: none;
        -ms-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .calibration-history-row-container {
        margin-left: 4em;

        .calibration-history-manager {
          padding: 0.2em 0.2em;
        }
      }
    }
  }

  .feedback-view-peer {
    .peer-review-detail {
      .reviewer-member {
        margin-bottom: 10px;

        span {
          margin-left: 6px;
        }
      }

      .peer-review-detail-row {
        padding: 0 45px;

        .question-index {
          font-size: $font-size-s;
          font-weight: 600;
          color: #373b3e;
        }

        > .row {
          margin-bottom: 20px;
        }
      }
    }
  }
}

// share with performance review screen
.feedback-input-overall-assessment,
.feedback-input-feedback {
  textarea {
    height: calc(100vh - 200px);
  }
}

.feedback-button-row {
  margin-top: 1em;
}

.feedback-info-container {
  .employee-row {
    margin-top: 1em;
  }

  .header-row {
    margin: 1em 0;

    h5 {
      font-size: 14px;
      font-weight: bold;
    }

    .border-right {
      border-right: 1px solid #c9c9c9;
    }
    .card {
      border: none !important;
      margin: 0 15px;

      .card-body {
        padding: 0;

        .card-title {
          margin-bottom: 2px;
        }
      }
      .card-text {
        font-size: 14px;
      }
    }
  }

  .feedback-point-row {
    margin-top: 3em;
  }

  .comment-row {
    margin-top: 2em;

    .comment-box {
      border: 1px solid rgba(0, 0, 0, 0.125);
      padding: 1em;
      min-height: 10em;
    }
  }

  .feedback-view-peer {
    .peer-review-detail {
      padding: 0 15px;
      margin-bottom: 20px;

      .reviewer-member {
        padding: 0;
        margin-bottom: 10px;

        span {
          margin-left: 6px;
        }
      }

      .peer-review-detail-row {
        padding: 20px;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }
    }
  }
}

.feedback-point-row {
  .feedback-input-performance-rating {
    select {
      width: 300px;
      padding: 0.2em 1em;
      -o-appearance: none;
      -ms-appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid rgba(0, 0, 0, 0.125);
    }

    &.select-bg-white {
      select {
        background-color: #ffffff !important;
      }
    }
  }
}

.feedback-assessments-reviewees-list {
  h3 {
    margin-bottom: 0;
    border: none;
  }

  .feedback-reviewees-list {
    .feedback-reviewees-list-header {
      position: sticky;
      z-index: 1030;
      top: 55px;
      font-weight: bold;
      border: 1px solid #ccc;
      border-style: solid none;
      background-color: #fafafa;

      .col {
        padding: 10px 4px 8px 4px;
      }

      .reviewees-list-status {
        text-align: center !important;
      }
    }

    a {
      text-decoration: none;
      color: #595959;
    }

    a:hover {
      .reviewees-list {
        background-color: #f1f8fb;

        .col {
          background-color: inherit;
        }
      }
    }

    .col-highlight {
      background-color: #fefbe6;
    }

    .reviewees-list {
      .col {
        padding: 10px 5px !important;
      }

      .reviewees-list-status {
        text-align: center;
      }
    }
  }
}

/* Feedback screen with new list design */
// TODO: Make all list style consistent - add to design system
.feedback {
  .guide {
    margin: 15px 0 30px;
  }

  &.list-container {
    margin-bottom: 40px;
    > .row {
      &.list-header {
        border: 1px solid $gray-6;
        border-radius: 3px;
        margin-bottom: 4px;

        > .col {
          padding: 11px 9px;
          font-size: $font-size-m;
          font-weight: 600;
          color: $gray-7;
          display: flex;
          align-items: center;

          &.inline {
            display: inline;
          }
        }

        .header-caption {
          font-weight: normal !important;
        }
      }
    }

    a {
      text-decoration: none;
    }

    .list-row {
      background-color: #fafbfc;
      border-radius: 4px;
      border: 1px solid transparent;

      &:hover {
        background-color: #ebf0ff;
        border: 1px solid $blue-7;
      }

      > .col {
        padding: 11px 9px;

        /* TODO: Add to design system definition */
        > .employee-icon {
          width: 40px;
        }

        .member-name,
        .manager-name {
          margin-left: 10px;
          color: #4a4a4a;
          font-size: $font-size-m;
          font-weight: 600;
          letter-spacing: 0.1px;
          vertical-align: middle;
          width: 130px;
          display: inline-block;
          overflow-wrap: break-word;
        }

        .manager-name {
          font-weight: normal;
        }

        &.completed {
          .employee-icon {
            filter: grayscale(1);
          }

          .member-name,
          .manager-name {
            color: $gray-6;
          }
        }

        .status-badge {
          vertical-align: middle;
          margin-top: 10px;
        }

        .view-span {
          font-size: $font-size-s;
          font-weight: 600;
          color: $blue-8;
          position: absolute;
          top: 20px; // align with status text
          right: 13px;

          div {
            display: inline-block;
          }

          .pp-icon {
            svg {
              margin-top: -3px;
              margin-left: 5px;

              g {
                fill: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

.feedback-timestamp-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.feedback-error-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.feedback-saving-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.feedback-saved-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}
