@import './people-products-variables';

.review-targets {
  text-align: center;
}

.not-assigned {
  background-color: $blue-2;
}

.non-rated {
  background-color: $blue-4;
}

.not-assigned-and-non-rated {
  background-color: $blue-6;
}

.not-assigned-button {
  margin-bottom: 15px;
  float: right;
}

.payroll-admin-container {
  .review-group-radio {
    padding-left: 15px;
  }

  svg {
    g {
      fill: $gray-3;
    }
  }

  .review-group-changed {
    background-color: $blue-2;

    svg {
      g {
        fill: $blue-5;
      }
    }
  }
}
