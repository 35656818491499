@import '_variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/react-redux-toastr/src/styles/index';
@import '../../node_modules/@enykeev/react-virtualized/styles';
@import './people-products-variables';
@import './common/_text-editor.scss';

// TODO: move to people ?
.employee-icon {
  width: 54px;
  border-radius: 27px;

  &.size-xs {
    width: 12px;
  }
  &.size-sm {
    width: 24px;
  }
  &.size-md {
    width: 44px !important;
  }

  &.incomplete {
    box-shadow: 0 0 0 3px $danger;
  }
}

h2.content-title {
  color: $dark;
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
}

.reviews-layout {
  display: flex;
  margin: 0;
  padding: 0;
  // TODO: enable below when the design migration completed
  //background-color: $gray-2;

  > .layout-item {
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  > .layout-sidebar {
    flex: 0 0 170px;
    height: 100vh;
    overflow: auto;
    &.layout-sidebar-sticky {
      position: sticky;
      top: 0;
      min-height: 100vh;
    }
    padding: 30px 25px 20px 15px;
    display: flex;
    flex-direction: column !important;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    color: #ffffff;
    font-size: $font-size-xs;
    background-color: #273135;
    transition: 250ms;

    a {
      cursor: pointer;
      text-decoration: none;
    }

    &.layout-sidebar-collapsed {
      flex: 0 0 64px;
      align-items: center;
      padding: 30px 0 20px 0;
      height: auto;
      overflow: visible;
    }

    > * {
      flex: 0 0 auto;
    }

    > ul.sidebar-menu {
      display: flex;
      flex-direction: column !important;
      flex-flow: nowrap;
      justify-content: flex-start;
      align-items: flex-end;
      list-style-type: none;
      margin: 0 0 20px 0;
      padding: 0;

      > li.sidebar-menu-item {
        position: relative;
        margin: 0 0 15px 0;
        padding: 0;
        text-align: right;
        line-height: 1;

        a {
          color: #ffffff;

          .sidebar-menu-icon,
          .sidebar-menu-title {
            opacity: 0.9;
          }

          &:hover {
            .sidebar-menu-icon,
            .sidebar-menu-title {
              opacity: 1;
            }
          }
        }

        &.sidebar-menu-item-active {
          a {
            font-weight: 500;

            .sidebar-menu-icon,
            .sidebar-menu-title {
              opacity: 1;
            }

            &:hover {
              .sidebar-menu-icon,
              .sidebar-menu-title {
                opacity: 0.9;
              }
            }
          }
        }

        &.sidebar-menu-item-active::after {
          position: absolute;
          top: 2px;
          right: -15px;
          display: block;

          content: ' ';
          background-image: url('/images/sidebar/icon_pointer.svg');
          background-size: 7px 9px;
          height: 9px;
          width: 7px;
        }

        &:not(.sidebar-menu-item-collapsed) {
          .sidebar-menu-icon {
            padding-bottom: 1px;
            margin-right: 5px;
          }

          .sidebar-menu-title {
            white-space: nowrap;
          }
        }

        &.sidebar-menu-item-collapsed {
          margin-bottom: 10px;

          &.sidebar-menu-item-active::after {
            top: 10px;
            left: -11px;
          }

          &.sidebar-menu-item-active {
            a {
              background-color: rgba(255, 255, 255, 0.15);
            }
          }

          a {
            display: block;
            height: 30px;
            width: 30px;
            padding: 7px;
            border-radius: 4px;

            &:hover {
              background-color: rgba(255, 255, 255, 0.4);

              > .sidebar-menu-title {
                display: block;
              }
            }
          }

          .sidebar-menu-icon {
          }

          .sidebar-menu-circle {
            display: inline-block;
            width: 16px;
            height: 16px;
            background-color: #ffffff;
            border-radius: 8px;
          }

          .sidebar-menu-title {
            display: none;
            position: absolute;
            z-index: 1000;
            top: 7px;
            left: 50px;
            padding: 2px 5px;
            border-radius: 2px;
            background-color: $gray-8;
            color: #ffffff;
            font-size: $font-size-xs;
            line-height: 1;
            white-space: nowrap;
            opacity: 0.9 !important;
          }
        }
      }

      &.sidebar-debug-menu {
        /*
        > li.sidebar-menu-item {
          a {
            color: $yellow-8;

            &:hover {
              color: $yellow-6;
            }
          }
        }
         */
      }

      .sidebar-menu-spacer {
        width: 120px;
        background-color: #ffffff;
      }

      .sidebar-menu-spacer-collapsed {
        visibility: hidden;
      }
    }

    > .sidebar-locale {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;

      a:not(:hover) {
        opacity: 0.4;
      }

      > *:not(:first-child) {
        margin-left: 5px !important;
      }
    }

    > .sidebar-footer {
      width: 100%;
      text-align: center;

      p:not(:last-child) {
        margin-bottom: 10px;
      }

      a {
        color: $red-8;
        &:hover {
          color: $red-6;
        }
      }
    }
  }

  > .layout-content {
    &:not(.layout-content-no-overflow) {
      height: 100vh;
      overflow: auto;
      padding: 40px 30px;
    }

    > .content-wrapper {
      box-sizing: border-box;
      min-height: 100vh;
      margin: -40px -30px;
      padding: 40px 30px;
      // TODO: disable below when the design migration completed
      background-color: $gray-2;

      > .content-box,
      > * > .content-box {
        box-sizing: border-box;
        min-height: 800px;
        margin: 0;
        padding: 30px;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      }

      &:not(.full-width) > .content-box,
      &:not(.full-width) > * > .content-box {
        max-width: 1300px;
      }

      > .pp-breadcrumb,
      > * > .pp-breadcrumb {
        margin-bottom: 10px;
      }
    }

    // TODO: disable below when the design migration completed
    > :not(.content-wrapper) {
      &:not(.full-width) {
        max-width: 1218px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.sidebar-tooltip-en {
  left: 18px !important;
}

.sidebar-tooltip-ja {
  left: 36px !important;
}

.sidebar-tooltip,
.sidebar-collapsed-tooltip {
  .tooltip-inner {
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    a {
      cursor: pointer;
      color: $red-8;
      &:hover {
        color: $red-6;
      }
    }
  }

  .arrow::before {
    border-right-color: #ffffff;
  }
}

.flex-spacer {
  flex: 1 1 auto !important;
}

.no-border {
  border: none !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.nowrap {
  white-space: nowrap;
}

.light-text {
  color: rgba(0, 0, 0, 0.4);
}

.dark-text {
  color: rgba(0, 0, 0, 0.5);
}

.text-sm {
  font-size: 0.8em;
}

.margin-left-15px {
  margin-left: 15px;
}

.no-padding {
  padding: 0 !important;
}

.reviews-header-home {
  .reviews-header-logo {
    width: 90px;
    height: 20px;
  }
}

.header-dropdown-toggle.btn {
  border: none;
}

.header-dropdown-toggle.btn:focus,
.header-dropdown-toggle.btn:active,
.header-dropdown-item:focus,
.header-dropdown-item:active {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.transparent-bg {
  background-color: transparent !important;
  opacity: 1 !important;
}

.reviews-login-page-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  .reviews-splash {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
  }

  .sign-in-with-google-btn {
    width: 191px;
    height: 46px;
    cursor: pointer;
    padding: 0px;
    border-width: 0px;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background: url('/images/btn_google_signin_light_normal_web@2x.png') 0% 0% / cover transparent;

    &:active {
      background: url('/images/btn_google_signin_light_pressed_web@2x.png') 0% 0% / cover transparent;
    }
  }
}

.reviews-nologin-container {
  background: #ebedf0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.gap-plus {
  color: green;
  &::before {
    content: '+';
  }
}
.gap-minus {
  color: red;
  &::before {
    content: '';
  }
}

.option-hidden {
  display: none;
}

.badge-secondary,
.btn-secondary {
  color: #fff;
  &.close {
    color: $dark;
  }
}

.btn-outline-secondary:hover {
  color: #fff;
}

.btn-primary:disabled,
.btn-secondary:disabled,
.btn-info:disabled,
.btn-warning:disabled,
.btn-danger:disabled,
.btn-success:disabled,
.btn-light:disabled,
.btn-white:disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #dddddd;
  border-color: transparent;
  cursor: default;
}

.error-modal {
  .modal-body {
    ul {
      margin-bottom: 0;
    }
  }
}

@import 'fonts';
@import 'people-products';

ul.debug-nav {
  list-style-type: none;

  li.debug-nav-link {
    &:not(:last-child) {
      border-bottom: 1px solid $danger-color;
    }
  }
}

.debug-nav {
  $debug-color: $danger-color;
  $debug-bg: #eee;

  margin-right: 2rem;
  padding: 0.2rem;
  background-color: $debug-bg;
  border: 2px solid $debug-color;
  border-radius: 2px;
  color: #aaa;

  .debug-nav-label {
    display: inline-block;
    margin-right: 0.2rem;
    padding: 0.2rem;
    background-color: $debug-color;
    color: #fff;
    font-weight: 700;
  }
  .debug-nav-link a {
    display: inline-block;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    color: $debug-color;
  }
}

.lang-nav {
  padding: 0px 8px;
  .lang-nav-item {
    cursor: pointer;
    opacity: 0.4;
    &:hover {
      opacity: initial;
    }
    &.current {
      cursor: initial;
      opacity: initial;
    }
    &:not(:first-child) {
      margin-left: 5px;
    }
    img {
      height: 20px;
      width: 20px;
    }
    // "ja" icon has downward pointer triangle
    img.lang-ja {
      margin-top: 3px;
    }
    // "en" icon has upward pointer triangle
    img.lang-en {
      margin-bottom: 3px;
    }
  }
}

//FIXME: redux-toastr has fixed styles that is easy to break
// centering message
.redux-toastr .toastr .rrt-middle-container {
  min-height: 70px;
  display: flex;
  align-items: center;
}

@import '_admin';
@import '_dashboard';
@import '_calibration-screens';
@import '_assessments-reviewees';
@import '_feedback';
@import '_okr';
@import '_peer-review';
@import '_performance-review';
@import '_payroll-admin';
@import '_review-event-admin';
@import '_review-targets-admin';
@import '_maintenance';

@import './common/_reviewManagerAssignment';
@import './common/_item-selector';
@import './common/_status-badge';
