/**
 * assessments_reviewees
 */

/**
 * /assessments/{cycleId}/reviewees
 */

.assessments-reviewees {
  h2 {
    font-weight: bold;
    border-bottom: solid 1px #ccc;
    padding-bottom: 16px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  h3 {
    border-bottom: solid 1px #ccc;
    padding-bottom: 16px;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .assessments-reviewees-list {
    .row.reviewees-list-header {
      &:first-child {
        border-top: solid 1px #f0f0f0;
      }
      border-bottom: solid 1px #f0f0f0;
      padding: 8px 0;

      &:hover {
        background-color: #f1f8fb;
      }

      &.list-header {
        background: #f0f0f0;
        font-weight: bold;
      }
    }
  }
}

//table
.assessments-reviewees-list {
  // should feedback to 本人
  .should-feedback {
    color: #e83e8c;
  }

  > .row {
    border-top: solid 1px #dee2e6;
    &:last-child {
      border-bottom: solid 1px #dee2e6;
    }
  }

  .row.reviewees-list-header {
    position: sticky;
    top: 53px;
    background: #fcfcfc;
    z-index: 1030;
    font-weight: bold;

    border-bottom: solid 1px #ccc;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);

    .col {
      padding: 8px 4px;
    }
  }

  .row:hover .col {
    background-color: #f1f8fb;
  }

  // base csss
  .row.reviewees-list {
    border-bottom: solid 1px #f0f0f0;
  }
  .row.reviewees-list .col {
    padding: 8px 4px;

    .next-manager {
      font-weight: bold;
    }
    .previous {
      color: #555;
    }

    &.reviewees-list-reviewee {
    }

    &.reviewees-list-grades {
      white-space: nowrap;
    }

    &.reviewees-list-reviews {
      // FIXME 案件
      .career-note-text,
      .calibration-review-review-summary-text,
      .calibration-review-feedback-text {
        border-left: solid 4px #ccc;
        padding-left: 8px;
        margin-bottom: 8px;
      }
    }
  }

  .prev-calibration {
    border: solid 1px #ccc;
    padding: 8px;
  }
}

// detail screen
.assessments-info-for-manager-title {
}

.assessments-info-for-reviewee {
  padding-left: 0;
  padding-right: 0;

  border-bottom: solid 1px #ccc;
  margin-bottom: 16px;

  > .row {
    margin-bottom: 8px;
  }

  .feedback-review-summary,
  .feedback-feedback {
    textarea {
      min-height: 200px;
    }
  }
}
.assessments-info-for-manager {
  background: #fffae6;
  padding-left: 0;
  padding-right: 0;

  .assessments-careernote {
    textarea {
      min-height: 300px;
    }
  }
  .assessments-careernote-form {
    margin-top: 8px;
  }
}
