// copy variable from node_modules/bootstrap/scss if we need
@import './people-products-variables';

$font-size-base: 0.9rem !default; // Assumes the browser default, typically `16px`

// override bootstrap's theme
$primary: #1a90ff;
$secondary: #9b9b9b;
$danger: #ff6275;

$light: #e8e8e8;
$dark: #4a4a4a;
