/**
 * payroll admin screens
 */

@import './people-products-variables';

.payroll-admin-container {
  padding: 0;

  button {
    margin-left: 0 !important;
  }

  .payroll-admin-col {
    border-radius: 5px;
    padding: 20px;
    margin: 0 15px;
    background-color: rgba(85, 114, 237, 0.05);

    &.salary-update {
      min-height: 185px;
    }

    &.link-to-importer {
      a {
        color: #5572ed;
        font-size: 18px;
      }
    }
  }

  .salary-update-form-group {
    .custom-file-upload {
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
      border-radius: 4px;
      background-color: $blue-7;
      color: #ffffff;

      &:hover {
        background-color: $blue-5;
      }

      &.disabled {
        color: #9299ad;
        background-color: $gray-4;
        cursor: default;
      }
    }
    input {
      width: 400px;

      &.error-field {
        border: 1px solid #f15151;
        color: #e34646;
      }
    }
    p {
      color: #e34646;
      margin: 5px 0;
    }
  }

  input[type='file'] {
    display: none;
    z-index: -9999;
  }

  .import-action-buttons {
    button {
      margin-top: 5vh;
      float: right;
    }
  }

  .salary-update-table-view {
    overflow-y: scroll;
    height: 68vh;
    padding: 15px;
  }

  .salary-update-table-row {
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 12px;
    background-color: #f1f3f5;

    &.header-row {
      margin: 0;
      background-color: #ffffff;
      color: #5572ed;
      border: 1px solid #ced4da;
      font-size: 12px;
    }

    &.error-alert {
      border: 1px solid #f15151;
      border-radius: 4px;
      background-color: rgba(241, 81, 81, 0.1);
      margin: 0;
      color: #ce3f3f;
    }

    &.show-value-on-hover {
      .value {
        opacity: 0;
      }

      &:hover {
        background-color: #c1cdff;
        .value {
          opacity: 1;
        }
      }
    }

    .error-field {
      color: #e34646;

      span {
        margin-left: 5px;
      }
    }
  }

  .numeric-col {
    text-align: right;
  }

  .error-row {
    background-color: $red-3;
  }
}
