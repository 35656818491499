@import './people-products-variables';

// self assessment
.performance-review-self-assessment {
  .self-assessment-guide {
    margin: 15px 0;
    font-size: $font-size-m;
  }
}

// actions
.performance-review-actions {
  text-align: right;
}

.performance-review-screen-title {
  margin-bottom: 10px;
}

.performance-review-assessment-action {
  position: relative;
  border-top: solid 1px #ccc;
  background-color: white;
  padding: 16px 0;
  color: #4a4a4a;

  /* TODO: Make common scss file */
  .label {
    vertical-align: text-top;
    font-size: 12px;
    border-radius: 10px;
    padding: 2px 10px;
    &.required {
      background-color: $red-7;
      color: #fff;
    }
    &.optional {
      background-color: #9299a0;
      color: #fff;
    }
  }
}

.performance-review-container-request {
  width: 100% !important;
  padding: 10px 0 30px 0;
  overflow-y: scroll;
  position: relative;
  color: #4a4a4a !important;
}

.warning-text {
  color: #ff3952;
  margin-left: 5px;
  font-size: 0.8em;
}

.performance-review-container-wrapper:not(.content-wrapper) {
  width: inherit !important;
  margin-bottom: 50px !important;
}

.performance-review-container {
  padding: 10px 0;
}

.performance-review-section {
  margin-bottom: 40px;
}

.performance-review-content.table {
  border: 1px solid $gray-4;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;

  &.salary-update-history {
    table-layout: fixed;
  }

  thead {
    th {
      padding: 16px 20px;
      border-top: none;
      border-bottom: none;
      color: $text-muted-color;
      font-weight: 500;
      line-height: 1;
      overflow-wrap: break-word;
    }
  }

  &.performance-review-content-opened {
    thead {
      th {
        border-bottom: 1px solid $gray-4;
      }
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: $gray-0;
    }
    tr:nth-child(even) {
      background-color: $gray-1;
    }
    td {
      padding: 16px 20px;
      border-top: none;
      line-height: 1;
      overflow-wrap: break-word;
    }
  }
}

.performance-review-content:not(.table):not(.no-padding-col) {
  padding: 20px;
  border-radius: 4px;
  background-color: $gray-1;

  &.performance-review-content-text {
    padding: 14px 20px;
  }

  .objective-card {
    margin: 0 0 20px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.bg-gray {
  background-color: $gray-1;
  padding: 10px !important;
}

.performance-review-reviewer-rows {
  padding: 0 15px !important;

  > .col {
    padding: 10px 0;
    margin-bottom: 15px;
  }
}

.peer-review-reviewer-rows {
  margin: 0;
  padding: 15px 0 0 0 !important;
  > .col {
    padding: 10px 0;
  }

  > .peer-review-comments {
    padding: 20px 0;
    padding-top: 0 !important;
    border-bottom: solid 1px #e8e8e8;

    > .peer-review-content {
      > .sub-list {
        padding: 0 !important;
        > .row {
          margin-left: 0;
          margin-right: 0;
          > .col {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

/* Performance review screen with new list design */
// TODO: Make all list style consistent - add to design system
.performance-review-reviewer-rows {
  margin: 0;
  padding: 15px 0 0 0 !important;
}
.performance-review {
  .page-title {
    margin: 0;
  }

  .review-list-title {
    font-size: $font-size-l;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .review-list-desc {
    background: none #ebedf0;
    border-radius: 4px;
    padding: 10px 15px 15px;
    .title {
      font-weight: 600;
      font-size: $font-size-s;
    }
    .list {
      list-style: none;
      margin: 10px 0 0;
      padding: 0;
      font-size: $font-size-s;
    }
    .list-item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      display: flex;
      flex-direction: row;
    }
    .list-item-icon {
      width: 0.9em;
      height: 0.9em;
      margin-right: 0.7em;
      position: relative;
      top: 3px;
      left: 1px;
      flex: 0 0 0.9em;
    }
    .list-item-text {
      flex: 1 1 auto;
    }
  }
  .review-list-desc-as-review-manager {
    margin-bottom: 10px;
  }
  .review-list-desc-as-reviewer {
    margin-bottom: 10px;
  }

  &.list-container {
    margin-bottom: 40px;
    > .row {
      &.list-header {
        border: 1px solid $gray-6;
        border-radius: 3px;
        margin-bottom: 4px;

        > .col {
          padding: 11px 9px;
          font-size: $font-size-m;
          font-weight: 600;
          color: $gray-7;
        }
      }
    }

    a {
      text-decoration: none;
    }

    .list-row {
      background-color: #fafbfc;
      border-radius: 4px;
      border: 1px solid transparent;

      &:hover {
        background-color: #ebf0ff;
        border: 1px solid $blue-7;
      }

      > .col {
        padding: 11px 9px;

        /* TODO: Add to design system definition */
        > .employee-icon {
          width: 40px;
        }

        .member-name {
          margin-left: 10px;
          color: #4a4a4a;
          font-size: $font-size-m;
          font-weight: 600;
          letter-spacing: 0.1px;
          vertical-align: middle;
        }

        &.completed {
          .employee-icon {
            filter: grayscale(1);
          }

          .member-name {
            color: $gray-6;
          }
        }

        .status-badge {
          vertical-align: middle;
          margin-top: 10px;
        }

        .view-span {
          font-size: $font-size-s;
          font-weight: 600;
          color: $blue-8;
          position: absolute;
          top: 20px; // align with status text
          right: 13px;

          div {
            display: inline-block;
          }

          .pp-icon {
            svg {
              margin-top: -3px;
              margin-left: 5px;

              g {
                fill: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

.assessment-total-point {
  white-space: nowrap;

  input {
    display: inline;
    width: 55px;
  }

  button {
    padding: 5px 10px;
    margin: 0 5px;
  }
}

.assessment-rating-row {
  margin-bottom: 20px;
}

.performance-review-toggle {
  margin-bottom: 8px;
}

.self-assessment-timestamp-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.self-assessment-error-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.self-assessment-saving-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.self-assessment-saved-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.perf-review-timestamp-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.perf-review-error-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.perf-review-saving-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}

.perf-review-saved-badge {
  margin-top: -68px;
  margin-right: -30px !important;
  margin-bottom: 31px;
}
