@import './_variables';

.dashboard {
  /* deprecated */
  .task-table {
    th {
      color: $dark;
    }

    .task-table-event {
    }

    .task-table-cycle {
      width: 25%;
    }

    .task-table-status {
      width: 25%;
    }
  }
}

.tooltip-schedule-details {
  .tooltip-inner {
    left: 16px;
    right: 0;
    min-width: max-content;
    padding: 6px 8px;
    color: #fff;
    text-align: justify;
    background-color: #000;
    border-radius: 0.25rem;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.89);
    top: -15px;
  }

  .arrow::before {
    left: 10px;
    right: 0;
    border-width: 0.5rem 0.7rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.89);
    top: -6px;
  }
}

@media screen and (max-width: 1200px) {
  .dashboard-task-description {
    display: none;
  }
}
