@import './people-products-variables';

.review-event-select-preparing {
  color: white;
  background-color: $primary-color;
}

.review-event-select-in_progress {
  color: white;
  background-color: $success-color;
}

.review-event-select-done {
  color: white;
  background-color: $gray-4;
}
